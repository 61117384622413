<template>
  <div class="bg-white flex flex-col h-full">
    <div class="container mx-auto px-4 my-auto flex grow" style="flex-grow: 1;">
      <section class="py-8 px-4 text-center mx-auto">
        <div class="max-w-auto mx-auto mt-auto flex-row">
          <div class="md:max-w-lg mx-auto w-full"><img class=" block img-404 w-52" src="@/assets/images/404.svg" alt="">
          </div>
          <div class="body-404 mx-auto">
            <h2 class="mt-8  text-lg lg:text-2xl font-black header-msg">{{ $t('comman.404') }}</h2>
            <p class=" list-item">{{ $t('comman.404_des') }}</p>
          </div>
        </div>
      </section>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
export default {
  name: 'error',
  components: {
    Footer
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header-msg {
  text-transform: capitalize;
  font-weight: 400;
  font-stretch: normal;
  font-size: 29px;
  text-align: center;
  margin: 1rem 0;
}

.list-item {
  text-align: center;
  font-stretch: normal;
  color: #444444;
  font-size: 15px;
  opacity: 1;
  font-weight: 300;
  line-height: 2;
  margin-left: 11px;
}

section {
  width: 100%;
  margin-top: 10%;
}

.img-404 {
  width: 70%;
  margin: auto;
}

@media only screen and (max-height: 600px) {
  .img-404 {
    width: 30%;
  }

  .header-msg {
    font-size: 14px;
  }

  .list-item {
    font-size: 12px;
  }

  section {
    margin-top: 10%;

  }

  .card-section {
    margin-bottom: 10%;
  }

}

@media only screen and (max-width: 992px) and (min-height: 601px) {
  .img-404 {
    width: 60%;
  }

  .header-msg {
    font-size: 18px;
  }

  .list-item {
    font-size: 12px;
  }

  section {
    margin-top: 30%;
  }

  .body-404 {
    width: 73%;
  }
}
</style>
