import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Cleave from 'cleave.js'
import * as Sentry from '@sentry/vue'
import { ValidationProvider, ValidationObserver, extend, setInteractionMode } from 'vee-validate'
// eslint-disable-next-line camelcase
import { required, min, alpha_spaces, max } from 'vee-validate/dist/rules.umd'

import '@/assets/css/tailwind.css'
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import ar from './locales/ar.json'
Vue.directive('cleave', {
  inserted: (el, binding) => {
    el.cleave = new Cleave(el, binding.value || {})
  },
  update: (el) => {
    const event = new Event('input', { bubbles: true })
    setTimeout(function () {
      el.value = el.cleave.properties.result
      el.dispatchEvent(event)
    }, 100)
  }
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Sentry.init({
  App,
  dsn: 'https://a7b482a55a01e17bf97511012bff627c@sentry.thawani.om/5',
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 0.1, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 0.1 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

extend('required', {
  ...required,
  message: () => i18n.t('validation.required')
})
extend('min', {
  ...min,
  // message: '{_field_} is incomplete'
  message: (field) => {
    return i18n.t('validation.incomplete_card', { field: field })
  }
})
extend('alpha_spaces', {
  // eslint-disable-next-line camelcase
  ...alpha_spaces,
  message: () => i18n.t('validation.alpha_spaces')
})
extend('max', {
  ...max,
  message: () => i18n.t('validation.max')
})
extend('english_letters', {
  validate: value => /^[A-Za-z\s]+$/.test(value), // Matches only English letters
  // message: () => i18n.t('validation.english_letters') // Custom message from i18n
  message: (field) => {
    return i18n.t('validation.english_letters', { field: field })
  }
})
extend('card_date', {
  validate (value) {
    const date = value.split(' /')
    const month = parseInt(date[0].trim())
    const year = parseInt(date[1].trim())
    const today = new Date() // gets the current date
    let todayMonth = today.getMonth() + 1 // extracts the month portion
    const todayYear = today.getFullYear() % 100 // extracts the year portion and changes it from yyyy to yy format
    if (todayMonth < 10) { // if today's month is less than 10
      todayMonth = '0' + todayMonth // prefix it with a '0' to make it 2 digits
    }
    return year > todayYear || (year === todayYear && month >= todayMonth)
  },
  message: () => i18n.t('validation.date')
})
setInteractionMode('passive')

Vue.config.productionTip = false
Vue.use(VueI18n)
// Create the Vue I18n instance
const i18n = new VueI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    ar
  }
})
new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
