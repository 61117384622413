<template>
  <div class="flex flex-col min-h-screen  bg-white overflow-hidden " :class="{ 'rtl': isRtl }" :dir="isRtl ? 'rtl' : 'ltr'">
    <div class="spin-loading" v-show="isLoading">
      <div style="border-top-color:transparent"
        class="w-11 h-11 border-4 border-green border-solid rounded-full animate-spin"></div>
    </div>
    <Header :showSummary="showSummary" :merchantName="merchantName" :merchantNameAr="merchantNameAr" :merchantLogo="merchantLogo"
      :showDetails="showDetails" @toggle-summary="toggleSummary" v-show="PaymentStatus.Unpaid"></Header>
    <div class="flex-grow  flex  items-center justify-center p-4" v-show="PaymentStatus.Unpaid">
      <div class="bg-card rounded-2xl p-2  lg:p-6 w-full max-w-5xl">
        <div class="flex flex-col summerySec lg:flex-row">
          <div class="lg:flex-1 lg:w-2/3 mb-0 lg:mb-0 mr-0 lg:mr-4 lg:border-r border-gray-200" :class="{'summerySecDiv-rtl': isRtl}">
            <transition name="fade">
              <ProductList :items="products" :totalAmount="totalAmount" :merchantName="merchantName"
                :merchantLogo="merchantLogo" :showDetails="showDetails" @toggle-details="toggleDetails"
                v-if="showSummary" />
            </transition>
          </div>
          <div class="flex-1 lg:w-1/3">
            <tabs :sessionData="[sessionData]" :qrCodeData="codeWithCrc" @selectedTabData="handleDataFromChild"
              @refreshSession="handlerefreshSession" />
          </div>
        </div>
      </div>
    </div>

    <div class="flex items-center justify-center  bg-white overflow-hidden" style="min-height: 90vh;"
      v-show="PaymentStatus.Cancelled">
      <div class="container mx-auto px-4 bg-card">
        <section class="py-24 px-4 text-center mx-auto">
          <div class="max-w-auto mx-auto  flex-col items-center" :class="{ 'ltr': isRtl }" :dir="isRtl ? 'ltr' : 'ltr'">
            <img class="m-auto" src="@/assets/images/Error.svg" alt="" style="width:40% !important">
            <h2 class="mt-8 text-xl lg:text-3xl font-black header-msg my-2">{{ $t('PaymentStatus.Cancelled') }}</h2>
              <h4 class="list-item">{{ $t('PaymentStatus.Cancelled_des') }}
              </h4>
          </div>
        </section>
      </div>
    </div>

    <div class="flex items-center justify-center  bg-white overflow-hidden" style="min-height: 90vh;"
      v-show="PaymentStatus.Paid">
      <div class="container mx-auto px-4 bg-card">
        <section class="py-24 px-4 text-center mx-auto">
          <div class="max-w-auto mx-auto  flex-col items-center" :class="{ 'ltr': isRtl }" :dir="isRtl ? 'ltr' : 'ltr'">
            <img class="m-auto" src="@/assets/images/Success.svg" alt="" style="width:40% !important">
            <h2 class="mt-8 text-xl lg:text-3xl font-black header-msg my-2">{{ $t('PaymentStatus.paid') }}</h2>
              <h4 class="list-item">{{ $t('PaymentStatus.paid_des') }}
              </h4>

              <div class="mb-4 flex flex-col gap-3  my-5 success_data" >
          <div class="text-gray-700 flex justify-between"
            :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
            <span class="font-normal text-xs header label">{{ $t('authorize.Merchant_name') }}</span>
            <span class="font-normal text-xs">
              {{ displayMerchantName }}
            </span>

          </div>
          <div class="text-gray-700 flex justify-between "
            :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
            <span class="font-normal text-xs header label"> {{ $t('authorize.amount') }}</span>
            <span class="font-normal text-xs" :class="{ 'order-1': isRtl, 'order-2': !isRtl }">
              {{ $t('comman.OMR') }} {{ (totalAmount / 1000).toFixed(3) }}
            </span>
          </div>
        </div>
          </div>
        </section>
      </div>
    </div>
    <div class="flex items-center justify-center  bg-white overflow-hidden" style="min-height: 90vh;"
      v-show="PaymentStatus.Expired">
      <div class="container mx-auto px-4 bg-card">
        <section class="py-24 px-4 text-center mx-auto">
          <div class="max-w-auto mx-auto  flex-col items-center" :class="{ 'ltr': isRtl }" :dir="isRtl ? 'ltr' : 'ltr'">
            <img class="m-auto" src="@/assets/images/time-out.svg" alt="" style="width:40% !important">
            <h2 class="mt-8 text-xl lg:text-3xl font-black header-msg my-2">{{ $t('PaymentStatus.expired') }}</h2>
              <h4 class="list-item">{{ $t('PaymentStatus.expired_des') }}
                <br>
                {{ $t('PaymentStatus.expired_des_1') }}
              </h4>
              <a class="downloadBtn" :href="cancelUrl" target="_blank">
                {{ $t('home.return_button') }}
              </a>
          </div>
        </section>
      </div>
    </div>
    <Footer v-show="PaymentStatus.Unpaid || PaymentStatus.Cancelled || PaymentStatus.Paid || PaymentStatus.Expired">
    </Footer>
  </div>
</template>

<script>
import ProductList from '../components/ProductList'
import tabs from '../components/tabs.vue'
import Footer from '../components/Footer.vue'
import Header from '../components/Header.vue'
import Checkout from '../API/Checkout'
// import crc from 'crc'
const checkout = new Checkout()
export default {
  components: {
    ProductList,
    tabs,
    Footer,
    Header
  },
  data () {
    return {
      dataFromChild: {
        number_of_inquire_retry: 0,
        inputData: '',
        checksum: '',
        interval_between_inquire_retry_in_seconds: 0,
        selectedTab: null
      },
      DataRefreshSession: {
        isrefreshSession: false,
        selectedTab: null
      },
      showDetails: true,
      PaymentStatus: {
        Unpaid: false,
        Paid: false,
        Cancelled: false,
        Expired: false
      },
      isLoading: false,
      showSummary: false,
      isCancel: false,
      isQRPay: false,
      qrCodeData: '',
      products: [],
      CheckoutDetails: [],
      sessionData: [],
      totalAmount: 0,
      merchantName: '',
      merchantNameAr: '',
      merchantLogo: '',
      businessUrl: process.env.VUE_APP_MERCHANT_URL,
      cancelUrl: '',
      successUrl: '',
      isCybersourceDown: false,
      isOmanNetDown: false,
      isSaveCardAllowed: false,
      isAutoSaveCard: false,
      paymentPageStatus: null,
      otpLoad: false,
      show3dSecure: false,
      cyberSourceOrganizationId: process.env.VUE_APP_CYBERSOURCE_ORGANIZATION_ID,
      isCheckboxSeen: false,
      codePersonalInfo: '',
      name: '',
      crcTable: new Uint16Array(256),
      codeWithCrc: '',
      isQRLoading: false
    }
  },
  created () {
    this.checkWindowWidth()
    window.addEventListener('resize', this.checkWindowWidth)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.checkWindowWidth)
  },
  computed: {
    isRtl () {
      return this.$i18n.locale === 'ar'
    },
    buttonText () {
      return this.$i18n.locale === 'en' ? 'Switch to Arabic' : 'Switch to English'
    },
    displayMerchantName () {
      if (this.$i18n.locale === 'ar' && !this.merchantNameAr) {
        return this.merchantName // Fallback to English if Arabic name is null
      }
      return this.$i18n.locale === 'en' ? this.merchantName : this.merchantNameAr
    }
  },
  methods: {
    handleDataFromChild (data) {
      this.dataFromChild = data
      if (this.dataFromChild.selectedTab === 'scan-qr') {
        this.isQRLoading = true
        this.getQR_Data()
        this.inquireSession()
      }
      if (this.dataFromChild.selectedTab === 'deep-link') {
        this.inquireSession()
      }
    },
    handlerefreshSession (data) {
      this.getSession()
    },
    cardChanged (type) {
      this.cardType = type
    },
    InitiatePayment () {
      this.isFailed = false
      this.$refs.paymentForm.validate().then(async (success) => {
        if (!success) {
          if (this.cardType === 'unknown') {
            this.$refs.form.setErrors({
              card: [this.$t('validation.invalid_card_number')]
            })
          }
          return
        }
        try {
          this.purchaseLoad = true
          const response = await checkout.InitializePaymentAsync(
            this.$route.params.session,
            this.$route.query.key,
            this.cardInformation
          )
          if (response.data.code === 2100) {
            this.isSuccess = true
            setTimeout(() => {
              window.location.href = this.successUrl
            }, 1000)
            // OmanNet
          } else if (response.data.code === 2101) {
            this.showOtp = true
            this.otp = ''
            this.hash = response.data.data.hash
            this.receiptId = response.data.data.receipt_id
            this.paymentId = response.data.data.payment_id
            return
            // CyberSource
          } else if (response.data.code === 2102) {
            // Start the Device Data collection procedure
            this.merchantId = response.data.data.merchant_id
            this.receiptId = response.data.data.receipt_id
            const form = document.createElement('form')
            form.target = 'cardinal-collection-iframe'
            form.method = 'POST'
            form.action = response.data.data.device_data_collection_url
            const accessTokenElement = document.createElement('input')
            accessTokenElement.type = 'hidden'
            accessTokenElement.value = response.data.data.access_token
            accessTokenElement.name = 'JWT'
            form.appendChild(accessTokenElement)
            document.body.appendChild(form)
            form.submit()
            form.remove()
            // Remove the existing element (if any)
            if (document.getElementById('deviceFingerprint') != null) {
              document.getElementById('deviceFingerprint').remove()
            }
            const deviceFingerPrintScript = document.createElement('script')
            deviceFingerPrintScript.id = 'deviceFingerprint'
            deviceFingerPrintScript.type = 'text/javascript'
            deviceFingerPrintScript.src = 'https://h.online-metrix.net/fp/tags.js?org_id=' + this
              .cyberSourceOrganizationId + '&session_id=' + this.merchantId + this.receiptId
            document.body.appendChild(deviceFingerPrintScript)
            // Remove the existing element (if any)
            if (document.getElementById('deviceFingerprintNoScript') != null) {
              document.getElementById('deviceFingerprintNoScript').remove()
            }
            const deviceFingerprintNoScript = document.createElement('noscript')
            deviceFingerprintNoScript.id = 'deviceFingerprintNoScript'
            const deviceFingerPrintIFrame = document.createElement('iframe')
            deviceFingerPrintIFrame.style =
              'width: 100px; height: 100px; border:0; position: absolute; top: -500px;'
            deviceFingerPrintIFrame.src = 'https://h.online-metrix.net/fp/tags.js?org_id=' + this
              .cyberSourceOrganizationId + '&session_id=' + this.merchantId + this.receiptId
            deviceFingerPrintIFrame.id = 'deviceFingerprintIFrame'
            deviceFingerprintNoScript.appendChild(deviceFingerPrintIFrame)
            document.body.appendChild(deviceFingerprintNoScript)
            return
          } else {
            this.$refs.paymentForm.setErrors({
              card: [this.$t('validation.card_declined')]
            })
          }
          this.purchaseLoad = false
        } catch (ex) {
          this.purchaseLoad = false
        }
      })
    },
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    toggleButtonState (event) {
      this.isTermsAccepted = event.target.checked
    },
    async ProceedWithOTP () {
      const result = await this.$refs.otpForm.validate()
      if (!result) {
        return
      }
      try {
        this.otpLoad = true
        const OTPResponse = await checkout.AuthorizedPaymentAsync(
          this.$route.params.session,
          this.$route.query.key,
          this.receiptId,
          this.paymentId,
          this.otp
        )
        if (OTPResponse.data.success) {
          await this.confirmPayment(true, true)
        } else {
          if (OTPResponse.data.code === 4206) {
            this.$refs.paymentForm.setErrors({
              card: [this.$t('validation.card_otp_failed')]
            })
          } else {
            this.$refs.paymentForm.setErrors({
              card: [this.$t('validation.card_declined')]
            })
          }
          this.receipt_id = ''
          this.payment_id = ''
          this.otp = ''
          this.otpLoad = false
          this.showOtp = false
          this.isSuccess = false
          this.purchaseLoad = false
        }
      } catch (ex) {
        this.receipt_id = ''
        this.payment_id = ''
        this.otp = ''
        this.otpLoad = false
        this.showOtp = false
        this.purchaseLoad = false
      }
    },
    async cancelSession () {
      try {
        this.isCancel = true
        const response = await checkout.cancelSessionAsync(
          this.$route.params.session,
          this.$route.query.key
        )
        if (response.status === 200) {
          window.location.href = this.cancelUrl
          this.isCancel = false
        }
      } catch (ex) {
        alert("You can't cancel in process transaction")
        this.isCancel = false
      }
    },
    toggleSummary () {
      this.showSummary = !this.showSummary
    },
    checkWindowWidth () {
      if (window.innerWidth > 1024) {
        this.showSummary = true
      } else {
        this.showSummary = false
      }
    },
    dSecureModal () {
      this.show3dSecure = !this.show3dSecure
      if (!this.show3dSecure) {
        this.purchaseLoad = false
        this.receipt_id = ''
        this.payment_id = ''
      }
    },
    async confirm3ds2Payment (isSuccess) {
      this.show3dSecure = false
      this.receipt_id = ''
      this.payment_id = ''
      this.purchaseLoad = false
      if (isSuccess) {
        this.isSuccess = true
        setTimeout(() => {
          window.location.href = this.successUrl
        }, 1000)
      } else {
        this.isSuccess = false
        this.isFailed = true
      }
    },
    async confirmPayment (isDebit, isSuccess) {
      if (isSuccess) {
        if (isDebit) {
          this.showOtp = false
          this.receipt_id = ''
          this.payment_id = ''
          this.otp = ''
          this.isSuccess = true
          this.purchaseLoad = false
          this.otpLoad = false
        } else {
          this.show3dSecure = false
          this.receipt_id = ''
          this.payment_id = ''
          this.isSuccess = true
          this.purchaseLoad = false
        }
        setTimeout(() => {
          window.location.href = this.successUrl
        }, 1000)
      } else {
        if (isDebit) {
          this.showOtp = false
          this.receipt_id = ''
          this.payment_id = ''
          this.otp = ''
          this.isSuccess = false
          this.purchaseLoad = false
          this.otpLoad = false
        } else {
          this.show3dSecure = false
          this.receipt_id = ''
          this.payment_id = ''
          this.isSuccess = false
          this.purchaseLoad = false
        }
        this.isFailed = true
      }
    },
    toggleLanguage (lang) {
      this.$refs.paymentForm.reset()
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'ar' : 'en' // change language
      localStorage.setItem('lang', this.$i18n.locale)
    },
    generateRandomNumber () {
      return Math.floor(Math.random() * 1000000000).toString().padStart(10, '0')
    },
    async getQR_Data () {
      const response = await checkout.getQRData(
        this.$route.params.session,
        this.$route.query.key
      )
      var touchPointId = response.data.tp_id // from API
      var mpcssAcountId = response.data.account_id // from API
      var activityCategoryCode = response.data.category_code // from API
      var MerchantId = response.data.inistitution_code + '-' + mpcssAcountId // from API
      var random10digit = this.generateRandomNumber()
      var MerchantRef = 'THWN' + touchPointId + random10digit
      var TransactionCurrencyCode = 'OMR'
      var CountryCode = 'OM'
      var MerchantName = response.data.branch_name // from API
      var MerchantCity = response.data.merchant_city // from API
      // var PostalCode = '6100'
      var code = ''
      // Payload Format Indicator
      code += '000201'
      // Point of initiation method
      code += '010211'
      // institutionCode-merchantId
      code += '08' + MerchantId.toString().length.toString().padStart(2, '0') + MerchantId
      // merchantTxnIdentifier
      // --------------------
      // Merchant Identifier
      var MerchantIdentifier = '01' + mpcssAcountId.toString().length.toString().padStart(2, '0') + mpcssAcountId
      // merchantCategoryCode
      var MerchantRefFormatted = '03' + MerchantRef.toString().length.toString().padStart(2, '0') + MerchantRef
      code += '26'
      code += (MerchantIdentifier.length + MerchantRefFormatted.length).toString().padStart(2, '0')
      code += MerchantIdentifier + MerchantRefFormatted
      // --------------------
      // Merchant Category Code
      code += '52' + activityCategoryCode.toString().length.toString().padStart(2, '0') + activityCategoryCode
      // Transaction Currency Code
      code += '53' + TransactionCurrencyCode.toString().length.toString().padStart(2, '0') + TransactionCurrencyCode
      // Country Code
      code += '58' + CountryCode.toString().length.toString().padStart(2, '0') + CountryCode
      // Merchant Name
      code += '59' + MerchantName.toString().length.toString().padStart(2, '0') + MerchantName
      // Merchant City
      code += '60' + MerchantCity.toString().length.toString().padStart(2, '0') + MerchantCity
      // Postal Code
      // code += PostalCode
      // CRC
      var TerminalId = '00' + response.data.terminal_id.toString().length.toString().padStart(2, '0') + response
        .data.terminal_id
      code += '62'
      code += TerminalId.length.toString().padStart(2, '0')
      code += TerminalId
      code += '63' + '04'
      this.generateCrcTable()
      const bytes = new TextEncoder().encode(code)
      const crc = this.calculateCrc(bytes)
      const crcString = crc.toString(16).toUpperCase().padStart(4, '0')
      this.codeWithCrc = `${code}${crcString}`
      if (this.codeWithCrc) {
        this.isQRLoading = false
      }
    },
    async inquireSession () {
      for (let i = 0; i < this.dataFromChild.number_of_inquire_retry; i++) {
        try {
          // Call your API
          await this.getSession()
        } catch (error) {

        }
        if (i < this.dataFromChild.number_of_inquire_retry - 1) {
          await this.wait(this.dataFromChild.interval_between_inquire_retry_in_seconds * 1000)
        }
      }
    },
    async getSession () {
      window.addEventListener('message', async (event) => {
        if (event.origin !== process.env.VUE_APP_BASE_URL && event.origin !== process.env
          .VUE_APP_CARDINAL_DATA_COLLECTION_URL) {
          return
        }
        if (event.data === '1' || event.data === '0') {
          await this.confirm3ds2Payment(event.data === '1')
        }
      })
      if (typeof this.$route.query.key === 'undefined') {
        await this.$router.push({
          path: '/404'
        })
        return
      }
      try {
        this.isLoading = false
        const response = await checkout.getCheckoutDetailsAsync(
          this.$route.params.session,
          this.$route.query.key
        )
        this.number_of_inquire_retry = response.data.number_of_inquire_retry
        this.interval_between_inquire_retry_in_seconds = response.data.interval_between_inquire_retry_in_seconds
        this.paymentPageStatus = response.data.payment_page_status
        if (this.paymentPageStatus === 1) {
          this.isLoading = false
          this.PaymentStatus = {
            Unpaid: true,
            Paid: false,
            Cancelled: false,
            Expired: false
          }
        }
        if (this.paymentPageStatus === 2) {
          this.isLoading = false
          this.PaymentStatus = {
            Unpaid: false,
            Paid: true,
            Cancelled: false,
            Expired: false
          }
          if (this.dataFromChild.selectedTab === 'deep-link' || this.dataFromChild.selectedTab === 'scan-qr') {
            setTimeout(() => {
              window.location.href = this.successUrl
            }, 3000)
          }
        }
        if (this.paymentPageStatus === 3) {
          this.isLoading = false
          this.PaymentStatus = {
            Unpaid: false,
            Paid: false,
            Cancelled: true,
            Expired: false
          }
        }
        if (this.paymentPageStatus === 4) {
          this.isLoading = false
          this.PaymentStatus = {
            Unpaid: false,
            Paid: false,
            Cancelled: false,
            Expired: true
          }
        }
      } catch (ex) {
        this.isLoading = false
        console.error('Error in getSession:', ex)
      } finally {
        this.isLoading = false
      }
    },
    wait (ms) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    generateCrcTable () {
      const polynomial = 0x1021
      for (let i = 0; i < 256; i++) {
        let crc = i << 8
        for (let j = 0; j < 8; j++) {
          if ((crc & 0x8000) !== 0) {
            crc = (crc << 1) ^ polynomial
          } else {
            crc = crc << 1
          }
        }
        this.crcTable[i] = crc & 0xFFFF
      }
    },
    calculateCrc (bytes) {
      let crc = 0xFFFF
      for (let i = 0; i < bytes.length; i++) {
        crc = (crc << 8) ^ this.crcTable[((crc >> 8) ^ bytes[i]) & 0xFF]
      }
      return crc & 0xFFFF
    }
  },
  async mounted () {
    window.addEventListener('message', async (event) => {
      if (event.origin !== process.env.VUE_APP_BASE_URL && event.origin !== process.env
        .VUE_APP_CARDINAL_DATA_COLLECTION_URL) {
        return
      }
      if (event.data === '1' || event.data === '0') {
        await this.confirm3ds2Payment(event.data === '1')
      }
    })
    if (typeof this.$route.query.key === 'undefined') {
      await this.$router.push({
        path: '/404'
      })
    }
    try {
      this.isLoading = true
      const response = await checkout.getCheckoutDetailsAsync(
        this.$route.params.session,
        this.$route.query.key
      )
      this.CheckoutDetails = response?.data
      this.sessionData = response?.data
      this.products = response?.data?.products
      this.totalAmount = response?.data?.total_amount
      this.merchantName = response?.data?.merchant
      this.merchantNameAr = response?.data?.merchant_ar ?? ''
      this.merchantLogo = response?.data?.logo
      this.cancelUrl = response?.data?.cancel_url
      this.successUrl = response?.data.success_url
      this.isSaveCardAllowed = response?.data?.is_save_card_allowed
      this.isCheckboxSeen = this.isSaveCardAllowed
      this.paymentPageStatus = response?.data?.payment_page_status
      this.isCybersourceDown = response?.data?.alert_cyber_source
      this.isOmanNetDown = response?.data?.alert_oman_net
      this.isQRPay = response?.data?.show_qr ?? ''
      if (this.paymentPageStatus === 1) {
        this.isLoading = false
        this.PaymentStatus = {
          Unpaid: true,
          Paid: false,
          Cancelled: false,
          Expired: false
        }
      }
      if (this.paymentPageStatus === 2) {
        this.isLoading = false
        this.PaymentStatus = {
          Unpaid: false,
          Paid: true,
          Cancelled: false,
          Expired: false
        }
      }
      if (this.paymentPageStatus === 3) {
        this.isLoading = false
        this.PaymentStatus = {
          Unpaid: false,
          Paid: false,
          Cancelled: true,
          Expired: false
        }
      }
      if (this.paymentPageStatus === 4) {
        this.isLoading = false
        this.PaymentStatus = {
          Unpaid: false,
          Paid: false,
          Cancelled: false,
          Expired: true
        }
      }
    } catch (ex) {
      this.isLoading = false
    }
    const tabs = document.querySelectorAll('[role="tab"]')
    const tabPanels = document.querySelectorAll('[role="tabpanel"]')
    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        tabs.forEach((t) => t.setAttribute('aria-selected', 'false'))
        tabs.forEach((t) => {
          t.classList.remove('selectedTab')
          t.classList.add('unselectedTab')
        })
        tabPanels.forEach((panel) => panel.classList.add('hidden'))
        tab.setAttribute('aria-selected', 'true')
        tab.classList.add('selectedTab')
        tab.classList.remove('unselectedTab')
        // Show the corresponding tab panel
        const tabPanel = document.getElementById(tab.getAttribute('aria-controls'))
        tabPanel.classList.remove('hidden')
      })
    })
  }
}
</script>
<style>
@import '../assets/css/home.css';

.rtl {
  direction: rtl;
}

.rtl .flex:not(.summerySec)  {
  flex-direction: row-reverse;
  /* place-items: center; */
}

.bg-card {
  background-color: #fbfbfb;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
input, textarea, select {
    /* width: 100%; */
    box-sizing: border-box;
}
.success_data{
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
  max-width: 272px;
  margin: auto;
    margin-top: auto !important;
  margin-top: 2rem !important;
}
.downloadBtn {
  background-color: unset;
  border: 1px solid #6EB74C;
  color: #6EB74C;
  display: flex !important;
  place-items: center;
    align-items: center;
  align-items: center;
  justify-content: center;
  height: 45px !important;
  border-radius: 8px;
  margin-top: 5px;
  width: 100% !important;
  margin-bottom: 11px;
  margin-top: 2rem;
}
</style>
