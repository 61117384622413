<template>
  <header class="p-4 lg:p-0  flex summerySec  justify-between items-center mt-5  w-full max-w-5xl m-auto">
    <div class="flex items-center">
      <div v-if="isMobile || isMobileView" :class="[{ 'ml-2': !isRtl, 'mr-2': isRtl }, 'rounded-full bg-white flex items-center justify-center MobileView']"
        @click="$emit('toggle-summary')">
        <svg :class="{ 'transform rotate-90': showSummary, 'transform -rotate-90': !showSummary }"
          xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 transition-transform duration-300" fill="none"
          viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
        </svg>
      </div>
      <div :class="[isRtl ? 'order-2' : 'order-1', 'w-10 h-10 rounded-full bg-white flex items-center justify-center']">
        <img :src="businessUrl + merchantLogo" alt="" class="rounded-full" />
      </div>
      <div :class="[isRtl ? 'order-1' : 'order-2', isRtl ? 'mr-3' : 'ml-3']">
        <h2 class="labelStyle">{{ displayMerchantName }}</h2>
      </div>
    </div>

    <div class="flex  shadow rounded-lg langBtn">
      <button :class="[
        'flex-1 py-2 px-3 rounded-l-lg flex items-center justify-center transition-colors',
        isEnglish ? 'langAr text-white rounded-l-lg  text-xs lg:text-sm' : 'bg-white text-black rounded-l-lg  text-xs lg:text-sm'
      ]" @click="toggleLanguage('en')">
        عربي
      </button>
      <button :class="[
        'flex-1 py-2 px-3 rounded-r-lg flex items-center justify-center transition-colors',
        isEnglish ? 'bg-white text-black rounded-r-lg  text-xs lg:text-sm' : 'langAr text-white rounded-r-lg text-xs lg:text-sm '
      ]" @click="toggleLanguage('ar')">
        English
      </button>
    </div>
  </header>
</template>

<script>
import {
  EventBus
} from '@/eventBus'
export default {
  props: {
    merchantName: {
      type: String,
      required: true
    },
    merchantNameAr: {
      type: String,
      required: true
    },
    merchantLogo: {
      type: String,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    },
    showSummary: {
      type: Boolean,
      required: true
    }
  },
  name: 'Header',
  data () {
    return {
      selectedLanguage: 'en',
      isMobile: false,
      businessUrl: process.env.VUE_APP_MERCHANT_URL
    }
  },
  created () {
    this.isMobile = this.isMobileDevice()
    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', 'en')
    } else {
      this.$i18n.locale = localStorage.getItem('lang')
    }
  },
  computed: {
    isRtl () {
      return this.$i18n.locale === 'ar'
    },
    isEnglish () {
      return this.$i18n.locale === 'ar'
    },
    isMobileView () {
      return window.innerWidth <= 768
    },
    displayMerchantName () {
      if (this.$i18n.locale === 'ar' && !this.merchantNameAr) {
        return this.merchantName // Fallback to English if Arabic name is null
      }
      return this.$i18n.locale === 'en' ? this.merchantName : this.merchantNameAr
    }
  },
  methods: {
    isMobileDevice () {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera
      const mobileRegex = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i
      return mobileRegex.test(userAgent.toLowerCase())
    },
    selectLanguage (language) {
      this.selectedLanguage = language || 'en'
    },
    checkWindowWidth () {
      if (window.innerWidth > 1024) {
        this.showSummary = true
      } else {
        this.showSummary = false
      }
    },
    toggleLanguage (lang) {
      // Emit the event to reset the form
      EventBus.$emit('languageChanged')
      this.selectedLanguage = lang
      this.$i18n.locale = this.selectedLanguage === 'en' ? 'ar' : 'en' // change language
      localStorage.setItem('lang', this.$i18n.locale)
    }
  }
}
</script>

<style scoped>
 @import '../assets/css/header.css';
</style>
