import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Authorize from '@/views/Authorize'
import _404 from '@/views/404.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/pay/:session',
    name: 'checkout',
    component: Home
  },
  {
    path: '/authorize/:paymentIntent',
    name: 'authorize',
    component: Authorize
  },
  {
    path: '*',
    component: _404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
