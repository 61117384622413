<template>
  <div class=" pr-0 pt-0 lg:pr-4 lg:pt-4   " :class="{'summeryS-rtl': isRtl}">

    <div class="container mx-auto py-4 px-2 lg:py-4 lg:px-4 ">
      <h2 class="labelStyle mb-4 block text-gray-700 labelStyle">{{ $t('productList.order_summary') }}</h2>
      <div class=" space-y-4">
        <div class="flex justify-between items-center pb-2" v-for="item in items" :key="item.name"
          :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">

          <div :class="{ 'order-2 ': isRtl, 'order-1': !isRtl }">
            <p class="text-ms font-semibold listLabel">{{ item.name }}</p>
            <p class="text-gray-500 text-xs">{{ $t('productList.quantity') }} {{ item.quantity }}</p>
          </div>

          <div :class="{ 'order-1': isRtl, 'order-2': !isRtl }">
            <p class="text-ms font-semibold listLabel text-end"> {{ $t('comman.OMR') }}
              {{ ((item.unit_amount * item.quantity) / 1000).toFixed(3) }}</p>
            <p class="text-gray-500 text-xs" v-if="item.quantity != 1"> {{ $t('comman.OMR') }}
              {{ (item.unit_amount / 1000).toFixed(3) }} {{ $t('comman.each') }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>

      <div class="flex justify-between items-center font-normal text-lg total mb-2 lg:mb-0"
        :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
        <label :class="{ 'order-2': isRtl, 'order-1': !isRtl }">{{ $t('comman.total') }}</label>
        <span :class="{ 'order-1': isRtl, 'order-2': !isRtl }"> {{ $t('comman.OMR') }}
          {{ formatAmount(totalAmount / 1000) }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      businessUrl: process.env.VUE_APP_MERCHANT_URL
    }
  },
  methods: {
    formatAmount (amount) {
      return new Intl.NumberFormat('en-US', { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(amount)
    }
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    merchantName: {
      type: String,
      required: true
    },
    totalAmount: {
      type: Number,
      required: true
    },
    merchantLogo: {
      type: String,
      required: true
    },
    showDetails: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isRtl () {
      return this.$i18n.locale === 'ar'
    }
  }
}
</script>
<style scoped>
@import '../assets/css/ProductList.css';

</style>
