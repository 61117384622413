import axios from 'axios'
import router from '@/router'
// Create a new instance.

const pendingRequests = new Map()
export const API_CONFIG = {
  headers: { 'Content-Type': 'application/json' },
  baseURL: `${process.env.VUE_APP_BASE_URL}/api/v1/`,
  withCredentials: true,
  responseType: 'json',
  xsrfCookieName: 'XSRF-TOKEN',
  xsrfHeaderName: 'X-XSRF-TOKEN',
  timeout: 50000,
  delayed: true
}

const instance = axios.create(API_CONFIG)

instance.interceptors.request.use(config => {
  if (config.delayed) {
    return new Promise(resolve => setTimeout(() => resolve(config), 1000))
  }

  if (config.cancelable) {
    const source = axios.CancelToken.source()
    const requestId = `${config.method}_${config.url}`
    const cancelToken = source.token
    addRequest(requestId, source.cancel)
    return { ...config, cancelToken, requestId }
  }
  return config
})

instance.interceptors.response.use(
  response => {
    const { requestId } = response.config
    if (requestId) {
      removeRequest(requestId)
    }
    return response
  },
  function (error) {
    if (error.response.status === 404) {
      router.push({
        path: '/404'
      })
      return
    }
    return Promise.reject(error)
  }
)

function addRequest (requestId, cancelFn) {
  cancelRequest(requestId)
  pendingRequests.set(requestId, cancelFn)
}

function removeRequest (requestId) {
  pendingRequests.delete(requestId)
}
function cancelRequest (requestId) {
  if (pendingRequests.has(requestId)) {
    const cancelFn = pendingRequests.get(requestId)
    cancelFn()
    removeRequest(requestId)
  }
}

export default instance
