<template>
  <footer class="p-4 m-auto justify-between items-center ">
    <div class="text-gray-600 flex gap-2 items-center">
      <template v-if="isRtl">
        <a href="https://thawani.om" class="text-green-500 order-1">
          <img class="h-6" src="@/assets/images/logo.svg" alt="Thawani Logo" />
        </a>
        <span class="order-2">{{ $t('comman.powered_by') }}</span>
      </template>
      <template v-else>
        <span class="order-1">{{ $t('comman.powered_by') }}</span>
        <a href="https://thawani.om" class="text-green-500 order-2">
          <img class="h-6" src="@/assets/images/logo.svg" alt="Thawani Logo" />
        </a>
      </template>
    </div>
    <div class="flex space-x-4 mt-2">
      <a href="https://www.facebook.com/ThawaniPay/" class="text-gray-500 hover:text-green-500"  target="_blank">
        <img class="w-6 h-6" src="@/assets/images/facebook.svg" alt="Facebook" />
      </a>
      <a href="https://www.instagram.com/thawani_pay/?hl=en" class="text-gray-500 hover:text-green-500"  target="_blank">
        <img class="w-6 h-6" src="@/assets/images/instagram.svg" alt="Instagram" />
      </a>
      <a href="https://www.linkedin.com/company/11481432/admin/" class="text-gray-500 hover:text-green-500"  target="_blank">
        <img class="w-6 h-6" src="@/assets/images/linkedin.svg" alt="linkedin" />
      </a>
      <a href="https://twitter.com/Thawani_Pay?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor" class="text-gray-500 hover:text-green-500"  target="_blank">
        <img class="w-6 h-6" src="@/assets/images/twitter.svg" alt="Twitter" />
      </a>
      <a href="https://www.youtube.com/channel/UCC7mkPwO_3Dua9KamWh2QNQ" class="text-gray-500 hover:text-green-500"  target="_blank">
        <img class="w-6 h-6" src="@/assets/images/youtube.svg" alt="YouTube" />
      </a>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  computed: {
    isRtl () {
      return this.$i18n.locale === 'ar'
    }
  }
}
</script>
<style scoped></style>
