<template>
  <div class="flex flex-col min-h-screen  bg-white ">
    <header class="p-4 lg:p-0  flex justify-end  mt-5 flex  w-full max-w-5xl m-auto">
      <div class="flex  shadow rounded">
        <button :class="[
          'flex-1 py-2 px-3 rounded-l-lg flex items-center justify-center transition-colors',
          isEnglish ? 'langAr text-white rounded-l-lg  text-xs lg:text-sm' : 'bg-white text-black rounded-l-lg  text-xs lg:text-sm'
        ]" @click="toggleLanguage('en')">
          عربي
        </button>
        <button :class="[
          'flex-1 py-2 px-3 rounded-r-lg flex items-center justify-center transition-colors',
          isEnglish ? 'bg-white text-black rounded-r-lg  text-xs lg:text-sm' : 'langAr text-white rounded-r-lg text-xs lg:text-sm '
        ]" @click="toggleLanguage('ar')">
          English
        </button>
      </div>
    </header>
    <div class="flex-grow  flex items-center justify-center p-4">
      <div class="bg-white p-8 rounded-lg shadow-lg w-full max-w-lg card-style">
        <div class="flex justify-center mb-4">
          <div class="p-0 rounded-full">

            <svg width="79" height="78" viewBox="0 0 79 78" fill="none" xmlns="http://www.w3.org/2000/svg"
              class="h-15 w-15 text-blue-500">
              <path opacity="0.5"
                d="M29.75 22.75C29.75 20.1641 30.7772 17.6842 32.6057 15.8557C34.4342 14.0272 36.9141 13 39.5 13C42.0859 13 44.5658 14.0272 46.3943 15.8557C48.2228 17.6842 49.25 20.1641 49.25 22.75V29.25H55.75V22.75C55.75 18.4402 54.038 14.307 50.9905 11.2595C47.943 8.21205 43.8098 6.5 39.5 6.5C35.1902 6.5 31.057 8.21205 28.0095 11.2595C24.962 14.307 23.25 18.4402 23.25 22.75V29.25H29.75V22.75ZM39.5 58.5C38.638 58.5 37.8114 58.1576 37.2019 57.5481C36.5924 56.9386 36.25 56.112 36.25 55.25V45.5C36.25 44.638 36.5924 43.8114 37.2019 43.2019C37.8114 42.5924 38.638 42.25 39.5 42.25C40.362 42.25 41.1886 42.5924 41.7981 43.2019C42.4076 43.8114 42.75 44.638 42.75 45.5V55.25C42.75 56.112 42.4076 56.9386 41.7981 57.5481C41.1886 58.1576 40.362 58.5 39.5 58.5Z"
                fill="#066274" />
              <path
                d="M55.75 29.25H23.25C20.6641 29.25 18.1842 30.2772 16.3557 32.1057C14.5272 33.9342 13.5 36.4141 13.5 39V61.75C13.5 64.3359 14.5272 66.8158 16.3557 68.6443C18.1842 70.4728 20.6641 71.5 23.25 71.5H55.75C58.3359 71.5 60.8158 70.4728 62.6443 68.6443C64.4728 66.8158 65.5 64.3359 65.5 61.75V39C65.5 36.4141 64.4728 33.9342 62.6443 32.1057C60.8158 30.2772 58.3359 29.25 55.75 29.25ZM42.75 55.25C42.75 56.112 42.4076 56.9386 41.7981 57.5481C41.1886 58.1576 40.362 58.5 39.5 58.5C38.638 58.5 37.8114 58.1576 37.2019 57.5481C36.5924 56.9386 36.25 56.112 36.25 55.25V45.5C36.25 44.638 36.5924 43.8114 37.2019 43.2019C37.8114 42.5924 38.638 42.25 39.5 42.25C40.362 42.25 41.1886 42.5924 41.7981 43.2019C42.4076 43.8114 42.75 44.638 42.75 45.5V55.25Z"
                fill="#066274" />
            </svg>

          </div>
        </div>
        <h2 class="text-xl font-medium text-center mb-4 header">{{ $t('authorize.authorise_payment') }}</h2>
        <div class="mb-4 flex flex-col gap-3  my-5" :class="{ 'flex-row-reverse': isRtl }">
          <div class="text-gray-700 flex justify-between"
            :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
            <span class="font-normal text-xs header label">{{ $t('authorize.Merchant_name') }}</span>
            <span class="font-normal text-xs">
              {{ paymentInformation.activityName }}
            </span>

          </div>
          <div class="text-gray-700 flex justify-between "
            :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
            <span class="font-normal text-xs header label"> {{ $t('authorize.amount') }}</span>
            <span class="font-normal text-xs">
              OMR {{ (paymentInformation.amount / 1000).toFixed(3) }}
            </span>
          </div>
          <div class="text-gray-700 flex justify-between"
            :class="{ 'justify-between': !isRtl, 'flex-row-reverse': isRtl }">
            <span class="font-normal text-xs header label"> {{ $t('authorize.card') }}</span>
            <span class="font-normal text-xs">
              {{ paymentInformation.paymentMethod }}
            </span>
          </div>
        </div>

        <validation-observer ref="otpForm">
          <form @submit.prevent="ProceedWithOTP">

            <div class="mt-8">
              <label for="otp" class="block text-sm font-medium leading-5 text-gray-700 mb-2" :class="{ 'text-right': isRtl }">
                {{ $t('authorize.enter_OTP_authorize') }}
              </label>
              <div class="mt-1 rounded-md shadow-sm relative">
                <validation-provider v-slot="{ failed, errors }" name="otp" rules="required">
                  <input inputmode="numeric" v-model="otpModel.otp" :placeholder="$t('comman.type_OTP')" :class="[
                    !failed ? '' : 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300 focus:shadow-outline-red',
                    'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 textField-style',
                    { 'text-right': isRtl }
                  ]" v-cleave="{
  blocks: [6],
  numericOnly: true,
}" autocomplete="off" id="otp" type="text"
                    class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md placeholder-gray-400 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5 textField-style">
                  <div id="otp-error"
                  class="absolute mt-2 top-10 left-0 text-xs my-1 error-msg-text text-right normal-case textError "
                  :class="{ 'right-0': isRtl, 'left-0': !isRtl }">
                  {{ errors[0] }}
                </div>
                </validation-provider>
              </div>
            </div>
            <div class="mt-2">
              <span class="block w-full rounded-md shadow-sm mt-8">
                <button :disabled="otpLoad || isSuccess || isFailed" type="submit"
                  class=" w-full text-white font-bold py-3 rounded-md payBtn labelBtn" :class="buttonClass">
                  <span v-show="!otpLoad && !isSuccess && !isFailed">
                    {{ $t('comman.verify_Pay') }}
                  </span>
                  <svg v-show="otpLoad" class="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      fill="currentColor"></path>
                  </svg>
                  <span v-show="otpLoad"> {{ $t('comman.verifying') }} </span>
                  <span v-show="isSuccess"> {{ $t('comman.successful') }} </span>
                  <span v-show="isFailed"> {{ $t('comman.payment_failed') }}</span>
                </button>
              </span>
            </div>
          </form>
        </validation-observer>
        <button type="button" class="mt-4 w-full text-center font-bold LabelColor labelBtn"
          :class="otpLoad ? 'text-gray-500 pointer-events-none' : 'text-red-500'" :disabled="otpLoad"
          @click.prevent="cancelSession">{{ $t('comman.cancel') }}</button>
      </div>

    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/Footer.vue'
import Checkout from '@/API/Checkout'
import {
  EventBus
} from '@/eventBus'
const checkout = new Checkout()
export default {
  name: 'Authorize',
  components: {
    Footer
  },
  data () {
    return {
      paymentInformation: {
        amount: 0,
        paymentMethod: '',
        activityName: '',
        returnUrl: ''
      },
      otpLoad: false,
      isSuccess: false,
      selectedLanguage: 'en',
      isFailed: false,
      buttonClass: '',
      otpModel: {
        key: '',
        paymentIntentId: '',
        clientSecret: '',
        invoice: '',
        otp: ''
      }
    }
  },
  computed: {
    isEnglish () {
      return this.$i18n.locale === 'ar'
    },
    isRtl () {
      return this.$i18n.locale === 'ar'
    }
  },
  methods: {
    selectLanguage (language) {
      this.selectedLanguage = language
    },
    async ProceedWithOTP () {
      const result = await this.$refs.otpForm.validate()
      if (!result) {
        return
      }
      try {
        this.otpLoad = true
        const otpResponse = await checkout.ConfirmPaymentIntentOtpAsync(
          this.otpModel.key,
          this.otpModel.paymentIntentId,
          this.otpModel.clientSecret,
          this.otpModel.invoice,
          this.otpModel.otp
        )
        if (otpResponse.data.success) {
          this.otpLoad = false
          this.isSuccess = true
          this.buttonClass = 'bg-green-500'
          setTimeout(() => {
            window.location.href = this.paymentInformation.returnUrl
          }, 1500)
        } else if (!otpResponse.data.success && otpResponse.data.code === 4206) {
          this.$refs.otpForm.setErrors({
            otp: [this.$t('validation.otp_failed')]
          })
          this.otpModel.invoice = otpResponse.data.message
          this.otpModel.otp = ''
          this.otpLoad = false
          this.buttonClass =
            'bg-indigo-600 hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700'
        } else {
          this.buttonClass = 'bg-red-500'
          this.otpLoad = false
          this.isFailed = true
          this.$refs.otpForm.setErrors({
            otp: [this.$t('validation.payment_failed')]
          })
          setTimeout(() => {
            window.location.href = this.paymentInformation.returnUrl
          }, 2500)
        }
      } catch (ex) {
        this.otpModel.otp = ''
        this.otpLoad = false
        this.isFailed = false
        this.isSuccess = false
      }
    },
    async cancelSession () {
      await checkout.cancelPaymentIntentAsync(
        this.otpModel.paymentIntentId,
        this.otpModel.clientSecret
      )
      window.location.href = this.paymentInformation.returnUrl
    },
    toggleLanguage (lang) {
      // Emit the event to reset the form
      EventBus.$emit('languageChanged')
      this.selectedLanguage = lang
      this.$i18n.locale = this.selectedLanguage === 'en' ? 'ar' : 'en' // change language
      localStorage.setItem('lang', this.$i18n.locale)
      if (this.$refs.otpForm) {
        this.$refs.otpForm.reset() // Assuming you have a reset method
      }
    }
  },
  async mounted () {
    const result = checkout.ExtractUrlJson(this.$route.params.paymentIntent)
    this.otpModel = {
      key: result.key,
      paymentIntentId: result.payment_intent_id,
      clientSecret: result.client_secret,
      invoice: result.invoice
    }
    try {
      const response = await checkout.getPaymentIntentDetailsAsync(
        result.payment_intent_id,
        result.key,
        result.client_secret
      )
      this.paymentInformation = {
        amount: response.data.amount,
        paymentMethod: response.data.payment_method,
        activityName: response.data.activity_name,
        returnUrl: response.data.return_url
      }
    } catch (ex) { }
  }
}
</script>
<style scoped>
.rtl {
  direction: rtl;
}

.rtl .flex:not(.summerySec)  {
  flex-direction: row-reverse;
  place-items: center;
}

.langAr {
  background-color: #6eb74c;
}

.bg-card {
  background-color: #fbfbfb;
}

.payBtn {
  background-color: #6EB74C;
  display: flex;
  place-items: center;
  justify-content: center;
  height: 45px;
  border-radius: 4px;
}

.labelBtn {
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 28px;
  border-radius: 8px;
}

.header {
  color: #444 !important;
}

.label {
  color: #757575 !important;
  font-size: 14px !important;
}

.card-style {
  box-shadow: 0px 0px 2px 0px #00000040;
  border-radius: 34px;

}

.textField-style {
  height: 45px;
  border: 1.5px solid #dbdbdb;
  border-radius: 8px;
}
.error-msg-text{
  color: #DA1B54;
}
</style>
