import $http from './HttpClient'
export default class Checkout {
  async getCheckoutDetailsAsync (sessionId, key) {
    let response
    try {
      response = await $http.get(`payment_page/${sessionId}`, {
        delayed: false,
        params: {
          key: key
        }
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async getPaymentIntentDetailsAsync (intentId, key, clientSecret) {
    let response
    try {
      response = await $http.get(`payment_page/${intentId}/payment_intents`, {
        delayed: false,
        params: {
          key: key,
          client_secret: clientSecret
        }
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async cancelSessionAsync (sessionId, key) {
    let response
    try {
      response = await $http.post('pay/cancel', {
        session: sessionId,
        key: key
      }, {
        delayed: false
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async cancelPaymentIntentAsync (intentId, clientSecret) {
    let response
    try {
      response = await $http.post(`pay/cancel/${intentId}/payment_intents?client_secret=${clientSecret}`, {}, {
        delayed: false
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async InitializePaymentAsync (sessionId, key, card) {
    let response
    try {
      response = await $http.post('pay/initialize', {
        session: sessionId,
        key: key,
        save_card: card.saveCard,
        nickname: card.nickname,
        card: {
          number: card.cardNumber.split(' ').join(''),
          date: card.date.split(' ').join(''),
          cvv: card.cvv,
          name: card.NameOnCard
        }
      }, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async EnrollAuthenticationAsync (receiptId, referenceId) {
    let response
    try {
      response = await $http.post('pay/enroll-authentication', {
        receipt_id: receiptId,
        reference_id: referenceId
      }, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async ValidateAuthenticationAsync (responseMessage, transactionId, md) {
    let response
    try {
      response = await $http.post('pay/validate-authentication-internal', {
        response: responseMessage,
        transaction_id: transactionId,
        md: md
      }, {
        delayed: true
      })
    } catch (ex) {
      console.log('asma 12')
      response = ex.response
    }
    return response
  }

  async getQRData (sessionId, key) {
    let response
    try {
      response = await $http.get(`payment_page/qr/${sessionId}`, {
        delayed: false,
        params: {
          key: key
        }
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async AuthorizedPaymentAsync (sessionId, key, receipt, paymentId, otp) {
    let response
    try {
      response = await $http.post('pay/authorize', {
        session: sessionId,
        key: key,
        receipt_Id: receipt,
        payment_id: paymentId,
        otp: otp
      }, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async ConfirmPaymentIntentOtpAsync (key, paymentIntentId, clientSecret, invoice, otp) {
    let response
    try {
      response = await $http.post('pay/authorize/confirm/otp', {
        key: key,
        payment_intent_id: paymentIntentId,
        client_secret: clientSecret,
        invoice: invoice,
        otp: otp
      }, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  ExtractUrlJson (url) {
    const decodedUrl = window.atob(decodeURIComponent(url))
    let str = ''
    for (let i = 0; i < decodedUrl.length; ++i) { str += String.fromCharCode(5 ^ decodedUrl.charCodeAt(i)) }
    return JSON.parse(str)
  }

  async ValidateMerchantAsync (params) {
    let response
    try {
      response = await $http.post('pay/apple-pay-session', params, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async authorizeApplePayPaymentAsync (params) {
    let response
    try {
      response = await $http.post('pay/apple-pay-authorize', params, {
        delayed: true
      })
    } catch (ex) {
      response = ex.response
    }
    return response
  }

  async processApplePayPaymentBase64 (sessionId, key, paymentDataBase64) {
    let response
    try {
      response = await $http.post(
        'generic/any',
        {
          session: sessionId,
          key: key,
          paymentDataBase64: paymentDataBase64
        },
        {
          delayed: true
        }
      )
    } catch (ex) {
      response = ex.response
    }
    return response
  }
}
